@import url(https://fonts.googleapis.com/css2?family=Heebo &display=swap);
@font-face {
  font-family: "nobelbold";
  src: url(/static/media/nobel-bold-webfont.e52ffc25.woff2) format("woff2"),
    url(/static/media/nobel-bold-webfont.2f5cb5cf.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "nobelregular";
  src: url(/static/media/nobel-regular-webfont.957ca1a1.woff2) format("woff2"),
    url(/static/media/nobel-regular-webfont.c28ef192.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "nobelbold", "Heebo", sans-serif;
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  white-space: pre-line;
}

a,
a:hover,
a:active {
  text-decoration: none;
}

path {
  stroke: inherit;
}

p {
  margin: 0;
}
.ReactModal__Body--open {
  overflow: hidden;
}
