@import url("https://fonts.googleapis.com/css2?family=Heebo &display=swap");

@font-face {
  font-family: "nobelbold";
  src: url("nobel-bold-webfont.woff2") format("woff2"),
    url("nobel-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "nobelregular";
  src: url("nobel-regular-webfont.woff2") format("woff2"),
    url("nobel-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "nobelbold", "Heebo", sans-serif;
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  white-space: pre-line;
}

a,
a:hover,
a:active {
  text-decoration: none;
}

path {
  stroke: inherit;
}

p {
  margin: 0;
}
.ReactModal__Body--open {
  overflow: hidden;
}